import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/permission", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/permission/${id}`);
};

export const create = async (data: any) => {
  return httpRequest.post(`/api/permission`, data);
};

export const update = async (data: any) => {
  return httpRequest.put(`/api/permission`, data);
};

export const active = async (id: string, status: string) => {
  return httpRequest.put(`/api/permission/status/${id}`, { status: status });
};
