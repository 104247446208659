import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/user", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/user/${id}`);
};

export const create = async (data: any) => {
  return httpRequest.post(`/api/user`, data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/api/user/${id}`, data);
};

export const active = async (id: string, status: string) => {
  return httpRequest.put(`/api/user/status/${id}`, { status: status });
};

export const addPermission = async (data: any) => {
  return httpRequest.post(`/api/user/permission`, data);
};
