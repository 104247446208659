import { ActionIcon, Box, Flex, LoadingOverlay, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PermissionApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import PermissionForm from "../../components/roles/permission-form";
import { Message } from "../../utils/message";

const Permission = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isDetail = location.state?.isDetail ?? searchParams.get("mode") === "detail";
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await PermissionApi.get(id);
        setData(response);
      } catch (error: any) {
        Message.error(error?.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <PageLayout
      title={
        <Flex align="center" gap="xs">
          <ActionIcon variant="light" onClick={() => navigate(-1)} size={38}>
            <IconArrowLeft />
          </ActionIcon>
          <Box>
            <Text size="md" fw={600}>
              {!id ? "Эрх нэмэх" : isDetail ? "Эрхийн дэлгэрэнгүй" : "Эрх засах"}
            </Text>
            <Text size="sm" c="gray">
              {!id ? "Шинээр хэрэглэгчийн эрх үүсгэх" : isDetail ? "Хэрэглэгчийн эрхийн дэлгэрэнгүй мэдээлэл" : "Хэрэглэгчийн эрхийг засварлах"}
            </Text>
          </Box>
        </Flex>
      }>
      <LoadingOverlay visible={isLoading} />
      <PermissionForm permission={data || undefined} isDetail={isDetail} permissionId={id || ""} />
    </PageLayout>
  );
};

export default Permission;
