import { createSlice } from "@reduxjs/toolkit";
import { IDehubGeneral } from "../interfaces/IGeneralDehub";

const initialState: IDehubGeneral = {
  modules: [],
  permissionStatus: [],
  roleStatus: [],
  userStatus: [],
  barimtStatus: [],
};

const dehubGenaralSlice = createSlice({
  name: "dehubGeneral",
  initialState,
  reducers: {
    initDehubGeneral: (state, { payload }: { payload: IDehubGeneral }) => {
      const { modules, permissionStatus, roleStatus, userStatus, barimtStatus } = payload;
      state.userStatus = userStatus;
      state.modules = modules;
      state.permissionStatus = permissionStatus;
      state.roleStatus = roleStatus;
      state.barimtStatus = barimtStatus;
      return state;
    },
  },
});

export const dehubGeneralReducer = dehubGenaralSlice.reducer;

export const { initDehubGeneral } = dehubGenaralSlice.actions;
