import { Button, Divider } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../../components/layout";
import UserList from "../../components/user/user-list";

const Users = () => {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  return (
    <PageLayout
      title="Системийн хэрэглэгчид"
      breadcrumb={breadcrumbs}
      extra={[<Button onClick={() => navigate("/users/list/create")}>Хэрэглэгч нэмэх</Button>]}>
      <Divider mb="xl" />
      <UserList />
    </PageLayout>
  );
};

export default Users;

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгчдийн жагсаалт",
  },
];
