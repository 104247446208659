import { Button, Divider, Flex, Grid, TextInput } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { Form } from "../form";
import { CheckboxRadioField } from "../form/checkbox-group-field";
import { DatePickerField } from "../form/date-picker-field";
import { RadioGroupField } from "../form/radio-group-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";

type IFormData = {
  type: string;
  partnerCategory: string;
  classification: string;
  regNumber: string;
  businessName: string;
  businessNameEng: string;
  legalEntityType: string;
  country: string;
  equityType: string;
  web: string;
  email: string;
  email2: string;
  phone: string;
  phone2: string;
  fbLink: string;
};

const schema = yup.object({
  legalEntityType: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  equityType: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  classification: yup.string().required("Заавал бөглөнө!").nullable(),
  businessName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё|-\s]{2,255}$/, "Зөвхөн крил үсэг болон - зураас авна!")
    .required("Заавал бөглөнө!"),
  businessNameEng: yup
    .string()
    .matches(/^[A-Za-z|-\s]{2,255}$/, "Зөвхөн латин үсэг авна!")
    .required("Заавал бөглөнө!"),
  regNumber: yup.string().when(["type"], (type) => {
    if (typeof type === "string" && type === "CITIZEN") {
      return yup
        .string()
        .required("Заавал бөглөнө!")
        .matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "формат буруу байна!")
        .min(10, "Регистрийн дугаар буруу байна!");
    } else if (typeof type === "string" && type === "COMPANY") {
      return yup
        .string()
        .required("Заавал бөглөнө!")
        .matches(/^[0-9]+$/, "формат буруу байна!")
        .min(7, "Улсын бүртгэлийн гэрчилгээний дугаар 7 оронтой тоо байна!");
    }
    return yup.string().nullable();
  }),
  isVatPayer: yup.boolean().optional(),
  email: yup.string().email("Цахим шууданг дахин шалгана уу!").required("Заавал бөглөнө!").max(255, "Хэтэрхий урит байна!"),
  phone: yup
    .string()
    .required("Заавал бөглөнө!")
    .matches(/^[9|8|7|6]{1}[0-9]{7}$/, "Утасны дугаарын формат буруу байна!")
    .min(8, "Утасны дугаар 8 оронтой тоо байна!"),
  partnerCategory: yup.string().required("Заавал бөглөнө!").nullable(),
});

type Props = {
  action: any[];
  onSubmit: (values: IFormData, type: boolean) => void;
};

const isActiveOptions = [
  {
    label: "Тийм",
    value: "true",
  },
  {
    label: "Үгүй",
    value: "false",
  },
];

export function PartnerManagementPartnerMainForm({ onSubmit: _onSubmit, action }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const [type, setType] = React.useState(false);
  const { equityTypes, legalEntityTypes, partnerCategories, partnerClassifications, partnerTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const [data] = React.useState<IFormData>({
    partnerCategory: "",
    classification: "",
    regNumber: "",
    businessName: "",
    businessNameEng: "",
    legalEntityType: "",
    country: "Монгол",
    equityType: "",
    web: "",
    email: "",
    email2: "",
    phone: "",
    phone2: "",
    fbLink: "",
    type: params.type,
    isActive: false,
    contractDate: "",
    serviceStartDate: "",

    ...((action && action[0] === "update") || (action && action[0] === "detail")
      ? {
          ...action[1],
          isActive: action[1]?.isActive?.toString() ?? "false",
          country: "Монгол",
        }
      : {}),
  });

  const onSubmit = (values: any) => {
    setTimeout(() => {
      _onSubmit(values, type);
    }, 400);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <Flex style={{ position: "relative" }} direction="column" justify="space-between" gap="md">
            <Grid gutter="sm">
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextInput label="Партнерийн код" placeholder="Авто үүснэ" value={values?.refCode} disabled />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextInput
                  label="Бүртгэсэн ажилтан"
                  placeholder="Авто үүснэ"
                  disabled
                  value={
                    values.regUser
                      ? (values?.regUser?.lastName !== null ? values?.regUser?.lastName : "-",
                        values?.regUser?.lastName !== null ? values?.regUser?.firstName : "-")
                      : ""
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField name="createdAt" disabled inputFormat="MM-DD-YYYY HH:mm" label="Бүртгэсэн огноо, цаг" placeholder="Авто үүснэ" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <CheckboxRadioField name="type" label="Партнерийн төрөл" options={partnerTypes} disabled />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  options={partnerClassifications?.map((c: any) => ({ label: c.name, value: c.code }))}
                  label="Партнерийн категори"
                  name="classification"
                  placeholder="Сонгох"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  options={(partnerCategories || [])?.map((c: any) => ({ label: c, value: c }))}
                  label="Партнерийн ангилал"
                  name="partnerCategory"
                  placeholder="Сонгох"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  label={values.type === "CITIZEN" ? "Регистрийн дугаар №" : "Татвар төлөгчийн дугаар №"}
                  name="regNumber"
                  placeholder={values.type === "CITIZEN" ? "Регистрийн дугаар №" : "Татвар төлөгчийн дугаар №"}
                  required
                  maxLength={values?.type === "CITIZEN" ? 10 : 7}
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  label="Партнерийн нэр /Монгол хэл/"
                  name="businessName"
                  placeholder="Партнерийн нэр /Монгол хэл/"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  label="Партнерийн нэр /Латин үсгээр/"
                  name="businessNameEng"
                  placeholder="Партнерийн нэр /Латин үсгээр/"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  options={legalEntityTypes?.map((c: any, index: number) => ({ label: c.name, value: c.code }))}
                  label="Аж ахуйн нэгжийн хэлбэр"
                  name="legalEntityType"
                  placeholder="Сонгох"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField label="Улсын харьяалал" name="country" placeholder="Монгол" disabled />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <SelectField
                  options={equityTypes?.map((c: any) => ({ label: c.name, value: c.code }))}
                  label="Өмчийн хэлбэр"
                  name="equityType"
                  placeholder="Өмчийн хэлбэр"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <RadioGroupField name="isActive" label="Гэрээ, бичиг баримт бүрэн" options={isActiveOptions} />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField name="contractDate" inputFormat="MM-DD-YYYY" label="Гэрээ баталгаажсан огноо" placeholder="Гэрээ баталгаажсан огноо" />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField name="serviceStartDate" inputFormat="MM-DD-YYYY" label="Үйлчилгээ эхлэх огноо" placeholder="Огноо сонгоно уу" />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField label="И-мэйл хаяг №1" name="email" placeholder="И-мэйл хаяг №1" required disabled={action && action[0] === "detail"} />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  nullable
                  label="И-мэйл хаяг №2"
                  name="email2"
                  placeholder="И-мэйл хаяг №2"
                  required={false}
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  nullable
                  label="Веб сайтын хаяг"
                  name="web"
                  placeholder="Веб сайтын хаяг"
                  required={false}
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  label="Утасны дугаар №1"
                  name="phone"
                  placeholder="Утасны дугаар №1"
                  required
                  maxLength={8}
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  label="Утасны дугаар №2"
                  name="phone2"
                  placeholder="Утасны дугаар №2"
                  required={false}
                  maxLength={8}
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  nullable
                  label="Facebook хаяг"
                  name="fbLink"
                  placeholder="Facebook хаяг"
                  required={false}
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>
            </Grid>
            <Divider />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/partner-management/partner")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  type="submit"
                  onClick={() => {
                    setType(true);
                  }}
                  disabled={action && action[0] === "detail"}>
                  Үргэжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
