import { Divider } from "@mantine/core";
import { PageLayout } from "../../components/layout";
import PermissionList from "../../components/roles/permission-list";

const Permissions = () => {
  const breadcrumbs = useBreadcrumb();
  return (
    <PageLayout title="Эрхийн жагсаалт" subTitle="Систем дэх эрх болон тэдгээрийн хяналт удирдлага" breadcrumb={breadcrumbs}>
      <Divider mb="lg" />
      <PermissionList />
    </PageLayout>
  );
};

export default Permissions;

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эрхийн жагсаалт",
  },
];
