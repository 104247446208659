import { Button, Divider, Modal } from "@mantine/core";
import { useState } from "react";
import { PageLayout } from "../../components/layout";
import RoleForm from "../../components/roles/role-form";
import RoleList from "../../components/roles/role-list";

const Roles = () => {
  const breadcrumbs = useBreadcrumb();
  const [opened, setOpened] = useState(false);

  return (
    <PageLayout
      title="Ролийн жагсаалт"
      subTitle="Систем дэх ролийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[<Button onClick={() => setOpened(true)}>Роль нэмэх</Button>]}>
      <Divider mb="xl" />
      <RoleList />
      <Modal opened={opened} onClose={() => setOpened(false)} size="sm" centered title="Роль бүртгэх">
        <RoleForm onClose={() => setOpened(false)} />
      </Modal>
    </PageLayout>
  );
};

export default Roles;

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгчдийн эрх",
  },
];
