import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { NavLink, useLocation } from "react-router-dom";
import { navigationDataMappedByLink, navigationDataMappedByParentLink } from "../../constants/navigation";

export function Navigation() {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentSection = currentPath.split("/")[1];
  const currentNavigation = navigationDataMappedByLink[`/${currentSection}`] || {};
  const navigation = navigationDataMappedByParentLink[`/${currentSection}`] || [];

  return (
    <AppShell.Navbar style={{ padding: "1rem" }}>
      {navigation.map((item, index) => (
        <NavItem item={item} key={index} color={currentNavigation.color} currentPath={currentPath} />
      ))}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: any;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, color, currentPath }: { item: INavItem; color: string; currentPath: string }) {
  const { classes, cx } = useStyles({ color });

  const isActive = currentPath === item.link || currentPath.startsWith(item.link + "/");

  return (
    <NavLink
      to={!item.disabled ? item.link : "#"}
      className={cx(classes.item, isActive && "active", item.disabled && "disabled")}
      aria-current={isActive ? "page" : undefined}>
      <item.icon className={classes.icon} size={40} />
      <Text size="xs">{item.title}</Text>
    </NavLink>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }) => {
  const iconRef = "icon";

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
      marginBottom: "1rem",
      textAlign: "center",
      textDecoration: "none",
      [`&.disabled`]: {
        pointerEvents: "none",
        opacity: 0.5,
      },
      [`&[aria-current="page"] .${iconRef}`]: {
        backgroundColor: theme.colors.indigo[6],
        color: theme.white,
      },
      [`&:hover .${iconRef}`]: {
        backgroundColor: theme.colors.indigo[6],
        color: theme.white,
      },
    },
    icon: {
      ref: iconRef,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors.indigo[6],
      backgroundColor: theme.colors.indigo[0],
      borderRadius: 4,
      padding: 8,
    },
  };
});
