import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { BasicSectorList } from "./components/basic-sector/list";
import { Layout } from "./components/layout";
import { SectorList } from "./components/sector/list";
import { SubSectorList } from "./components/sub-sector/list";
import { Access } from "./pages/access";
import Forgot from "./pages/auth/forgot";
import Login from "./pages/auth/login";
import { Verify } from "./pages/auth/verify";
import Receipts from "./pages/ebarimt/receipts";
import Homepage from "./pages/homepage";
import NoMatchInSection from "./pages/noMatchInSection";
import { PartnerManagementAdminLists } from "./pages/partner-management/admin";
import { PartnerManagementMerchant } from "./pages/partner-management/merchant";
import { PartnerManagementPartnerLists } from "./pages/partner-management/partner";
import { PartnerManagementPartnerDetail } from "./pages/partner-management/partner/detail";
import { PartnerManagementPartnerEdit } from "./pages/partner-management/partner/edit";
import { PartnerManagementPartnerNew } from "./pages/partner-management/partner/new";
import { Sector } from "./pages/partner-management/sector";
import { PartnerManagementUser } from "./pages/partner-management/user/list";
import { ReferenceB2bList } from "./pages/reference/b2b/list";
import { ReferencePaymentTermList } from "./pages/reference/payment-term/list";
import { ReferenceProductList } from "./pages/reference/product/list";
import { ReferenceProductType } from "./pages/reference/product/type";
import Users from "./pages/user/list";
import Permission from "./pages/user/permission";
import Permissions from "./pages/user/permissions";
import RoleDetail from "./pages/user/role-detail";
import Roles from "./pages/user/roles";
import UserDetail from "./pages/user/user-detail";
import { VerifyPage } from "./pages/verify";
import { authMe } from "./store/Auth";
import { initDehubGeneral } from "./store/DehubInit";
import { initGeneral } from "./store/General";

function App() {
  const [loading, setLoading] = React.useState(true);
  const { accessToken, sessionScope } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const init = React.useCallback(async () => {
    setLoading(true);
    try {
      if (accessToken) {
        const auth = await AuthApi.me();
        const resInit = await GeneralApi.init();
        const dehubInit = await GeneralApi.dehubInit();

        dispatch(initDehubGeneral(dehubInit));
        dispatch(initGeneral(resInit));
        dispatch(authMe(auth));
      }
    } catch (error: any) {
      console.error("Failed to fetch auth data:", error?.message);
    } finally {
      setLoading(false);
    }
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    init();
  }, [init]);

  if (loading)
    return (
      <div>
        <LoadingOverlay bg={"#adadad"} visible />
      </div>
    );
  else
    return (
      <Routes>
        <Route path="/access" element={<Access />} />
        {accessToken && sessionScope === "AUTHORIZED" ? (
          <>
            <Route index element={<Homepage />} />
            <Route path="settings" element={<Layout />}></Route>
            <Route path="/verify" element={<VerifyPage />} />

            <Route path="partner-management" element={<Layout />}>
              <Route path="/partner-management/partner" element={<PartnerManagementPartnerLists />} />
              <Route path="/partner-management/partner/:type/new" element={<PartnerManagementPartnerNew />} />
              <Route path="/partner-management/partner/:type/edit/:id/:step" element={<PartnerManagementPartnerEdit />} />
              <Route path="/partner-management/partner/:type/detail/:id/:step" element={<PartnerManagementPartnerDetail />} />
              <Route path="/partner-management/admin" element={<PartnerManagementAdminLists />} />
              <Route path="/partner-management/user" element={<PartnerManagementUser />} />
              <Route path="/partner-management/sector" element={<Sector />} />
              <Route path="/partner-management/sector/basic" element={<BasicSectorList />} />
              <Route path="/partner-management/sector/sector" element={<SectorList />} />
              <Route path="/partner-management/sector/sub-sector" element={<SubSectorList />} />

              <Route path="/partner-management/merchant" element={<PartnerManagementMerchant />} />
              <Route path="*" element={<NoMatchInSection />} />
            </Route>

            <Route path="reference" element={<Layout />}>
              <Route path="/reference/b2b-list" element={<ReferenceB2bList />} />
              <Route path="/reference/b2b-list/payment-term/:type" element={<ReferencePaymentTermList />} />
              <Route path="/reference/product-list" element={<ReferenceProductList />} />
              <Route path="/reference/product-list/:type" element={<ReferenceProductType />} />
            </Route>

            <Route path="ebarimt" element={<Layout />}>
              <Route path="/ebarimt/receipts" element={<Receipts />} />
            </Route>

            <Route path="users" element={<Layout />}>
              <Route path="/users/role" element={<Roles />} />
              <Route path="/users/role/:id" element={<RoleDetail />} />
              <Route path="/users/permissions" element={<Permissions />} />
              <Route path="/users/permissions/create" element={<Permission />} />
              <Route path="/users/permissions/:id/detail" element={<Permission />} />
              <Route path="/users/permissions/:id/update" element={<Permission />} />
              <Route path="/users/list" element={<Users />} />
              <Route path="/users/list/create" element={<UserDetail />} />
              <Route path="/users/list/:id/detail" element={<UserDetail />} />
              <Route path="/users/list/:id/update" element={<UserDetail />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/forgot" element={<Forgot />} />
            <Route path="/auth/verify/:id" element={<Verify />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </>
        )}
      </Routes>
    );
}

export default App;
