import { ActionIcon, Box, Flex, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useSwr from "swr";
import { DehubUserApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import UserForm from "../../components/user/user-form";

const UserDetail = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isDetail = location.state?.isDetail ?? searchParams.get("mode") === "detail";

  const { data } = useSwr(id ? `users.user.get.${id}` : null, async () => await DehubUserApi.get(id as string), {
    fallback: { data: null },
  });

  return (
    <PageLayout
      title={
        <Flex align="center" gap="xs">
          <ActionIcon variant="light" onClick={() => navigate("/users/list")} size={38}>
            <IconArrowLeft />
          </ActionIcon>
          <Box>
            <Text size="md" fw={600}>
              {!id ? "Шинэ хэрэглэгч үүсгэх" : isDetail ? "Хэрэглэгчийн дэлгэрэнгүй" : "Хэрэглэгч засварлах"}
            </Text>
            <Text size="sm" c="gray">
              {!id ? "DeHUB платформын ажилтан бүртгэх" : "Хэрэглэгчийн дэлгэрэнгүй задаргаа"}
            </Text>
          </Box>
        </Flex>
      }>
      <UserForm user={data || undefined} isDetail={isDetail} userId={id || ""} />
    </PageLayout>
  );
};

export default UserDetail;
