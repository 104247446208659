import { ActionIcon, Badge, Flex, Text, TextInput, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconCircleCheck, IconCircleMinus, IconEdit, IconEye, IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { DehubUserApi } from "../../apis";
import { Message } from "../../utils/message";
import { ColumnType, Table } from "../table";

const UserList = () => {
  const [query, setQuery] = useState<string>("");
  const [queryDebounced] = useDebouncedValue(query, 400);
  const [dataUpdated, setDataUpdated] = useState(false);
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key: any, record: any) => {
      switch (key) {
        case "edit": {
          navigate(`/users/list/${record?.id}/update?mode=${key}`, {
            state: { isDetail: key === "detail" },
          });
          break;
        }
        case "detail": {
          navigate(`/users/list/${record?.id}/detail?mode=${key}`, {
            state: { isDetail: key === "detail" },
          });
          break;
        }
        case "active": {
          openConfirmModal({
            title: `${record.userStatus === "ACTIVE" ? "Эрх идэвхгүй болгох" : "Эрх идэвхжүүлэх"}`,
            children: <Text>Хэрэглэгчийг {record.userStatus === "ACTIVE" ? "идэвхгүй" : "идэвхтэй"} болгох уу ?</Text>,
            labels: { confirm: `${record.userStatus === "ACTIVE" ? "Идэвхгүй болгох" : "Идэвхжүүлэх"}`, cancel: "Болих" },
            onConfirm: async () => {
              try {
                const res = await DehubUserApi.active(record.id, record?.userStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE");
                if (res) {
                  Message.success("Үйлдэл амжилттай");
                  setDataUpdated((prev) => !prev);
                }
              } catch (error: any) {
                Message.error(error?.message);
                setDataUpdated(false);
              }
            },
            centered: true,
          });
          break;
        }
      }
    },
  });

  return (
    <Flex direction="column" gap="lg">
      <TextInput leftSection={<IconSearch />} placeholder="Хэрэглэгч хайх" onChange={(e) => setQuery(e.target.value)} w={300} />
      <Table
        name={""}
        columns={columns}
        filters={{ query: queryDebounced }}
        loadData={(data) => DehubUserApi.list(data!)}
        key={dataUpdated ? "data-update" : "data"}
      />
    </Flex>
  );
};

export default UserList;

const useHeader = ({ onClick }: { onClick: (key: string, id: string) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Flex align="center" gap="xs">
        <Tooltip label="Засах">
          <ActionIcon variant="light" onClick={() => onClick("edit", record)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Дэлгэрэнгүй">
          <ActionIcon variant="light" onClick={() => onClick("detail", record)}>
            <IconEye />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={record?.userStatus === "ACTIVE" ? "Идэвхгүй болгох" : "Идэвхтэй болгох"} position="top">
          <ActionIcon variant="light" onClick={() => onClick("active", record ?? "-")}>
            {record?.userStatus === "ACTIVE" ? <IconCircleMinus /> : <IconCircleCheck />}
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  },
  {
    title: "Ургийн овог",
    render: (record) => <Text size="sm">{record?.familyName || "-"}</Text>,
  },
  {
    title: "Овог",
    render: (record) => <Text size="sm">{record?.lastName || "-"}</Text>,
  },
  {
    title: "Нэр",
    render: (record) => <Text size="sm">{record?.firstName || "-"}</Text>,
  },
  {
    title: "Регистрийн дугаар",
    render: (record) => <Text size="sm">{record?.registerNo || "-"}</Text>,
  },
  {
    title: "Цахим шуудан",
    render: (record) => <Text size="sm">{record?.email || "-"}</Text>,
  },
  {
    title: "Утас",
    render: (record) => <Text size="sm">{record?.phone || "-"}</Text>,
  },
  {
    title: "Статус",
    render: (record) => (
      <Badge color={record?.userStatus === "ACTIVE" ? "green.5" : "red.5"} variant="outline" radius="md">
        {record?.userStatus === "ACTIVE" ? "Идэвхтэй" : "Идэвхгүй"}
      </Badge>
    ),
  },
];
