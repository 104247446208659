import { ActionIcon, Button, Checkbox, Collapse, Flex, Paper, Stack, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconChevronUp, IconKey, IconUserCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { DehubUserApi } from "../../apis";
import { Message } from "../../utils/message";

const RolePermission = ({ data, userId, isDetail }: { data: any; userId: string; isDetail: boolean }) => {
  const [collapsedRoles, setCollapsedRoles] = useState<{ [key: string]: boolean }>({});
  const [collapsedPermissions, setCollapsedPermissions] = useState<{ [key: string]: boolean }>({});
  const [nodeObject, setNodeObject] = useState(data);
  const { classes } = useStyles();

  useEffect(() => {
    setNodeObject(data);
  }, [data]);

  const toggleRoleCollapse = (roleId: string) => {
    setCollapsedRoles((prev) => {
      const updatedState = { ...prev, [roleId]: !prev[roleId] };
      return updatedState;
    });
  };

  const togglePermissionCollapse = (permissionId: string) => {
    setCollapsedPermissions((prev) => {
      const updatedState = { ...prev, [permissionId]: !prev[permissionId] };
      return updatedState;
    });
  };

  const findParents = (key: string, isChecked: boolean) => {
    if (!key) return [];
    const parent: any = Object.values(nodeObject).find((i: any) => i.id === key);
    let current = undefined;

    const checkOtherChildren = Object.values(nodeObject).filter((i: any) => i.parent === key && i.isChecked === true);

    if (parent) {
      if (!isChecked && checkOtherChildren.length > 1) return;

      current = [parent];
      const grandParent: any = findParents(parent.parent, isChecked);
      if (grandParent) {
        current = [...current, ...grandParent];
      }
    }
    return current;
  };

  const findChildren = (keys: string[]) => {
    const children: any = Object.values(nodeObject).filter((i: any) => keys.includes(i.parent));
    let data: any = undefined;

    if (children.length) {
      data = [...children];
      const childNodes: any = findChildren(children.map((e: any) => e.id));
      if (childNodes) {
        data = [...data, ...childNodes];
      }
    }

    return data;
  };

  const handleToggle = (key: string, isChecked: boolean) => {
    const parents: any = findParents(nodeObject[key].parent, isChecked) || [];
    const children: any = findChildren([nodeObject[key].id]) || [];

    setNodeObject((prev: any) => {
      const updated = {
        ...prev,
        [key]: { ...prev[key], isChecked },
        ...parents.reduce((acc: any, c: any) => {
          acc[c.id] = {
            ...c,
            isChecked,
          };
          return acc;
        }, {}),
        ...children.reduce((acc: any, c: any) => {
          acc[c.id] = { ...c, isChecked };
          return acc;
        }, {}),
      };
      return updated;
    });
  };

  const submitRolePermission = async () => {
    try {
      const payload = {
        userId: userId,
        rolePermissionIds: Object.values(nodeObject)
          .filter((i: any) => i.isChecked && i.parent !== null)
          .map((j: any) => j.id),
      };
      const res = await DehubUserApi.addPermission(payload);
      if (res) {
        Message.success("Роль амжилттай нэмлээ");
      }
    } catch (error: any) {
      Message.error(error?.message);
    }
  };

  return (
    <Paper withBorder p="md" my="lg">
      <Flex align="center" gap="xs" mb="xs">
        <ActionIcon variant="light" size={20}>
          <IconUserCheck size={16} />
        </ActionIcon>
        <Text size="md" fw={600}>
          Хэрэглэгчийн роль
        </Text>
      </Flex>

      {Object.values(nodeObject)
        .filter((i: any) => i.parent === null)
        .map((r: any) => (
          <Stack key={r.id} gap={0}>
            <Flex align="center" justify="space-between" className={classes.item} style={{ background: collapsedRoles[r.id] ? "rgb(255, 245, 245)" : "" }}>
              <Flex align="center" gap="xs" style={{ flex: 1 }}>
                <Checkbox checked={r.isChecked} onChange={(v) => handleToggle(r.id, v.currentTarget.checked)} disabled={isDetail} />
                <Text onClick={() => toggleRoleCollapse(r.id)} style={{ cursor: "pointer", flex: 1 }} size="sm">
                  {r.nameMN}
                </Text>
              </Flex>
              <ActionIcon variant="light" onClick={() => toggleRoleCollapse(r.id)} size={20}>
                {collapsedRoles[r.id] ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
              </ActionIcon>
            </Flex>

            <Stack pl="lg" gap={0}>
              <Collapse in={!!collapsedRoles[r.id]}>
                <Flex align="center" gap="xs" my="xs">
                  <ActionIcon variant="light" size={20}>
                    <IconKey size={16} />
                  </ActionIcon>
                  <Text size="md" fw={600}>
                    Эрхүүд
                  </Text>
                </Flex>

                {Object.values(nodeObject)
                  .filter((i: any) => i.parent === r.id)
                  .map((p: any) => (
                    <div key={p.id}>
                      <Flex align="center" gap="sm" className={classes.item}>
                        <Checkbox checked={p.isChecked} onChange={(v) => handleToggle(p.id, v.currentTarget.checked)} disabled={isDetail} />
                        <Text onClick={() => togglePermissionCollapse(p.id)} style={{ cursor: "pointer" }} size="sm">
                          {p.nameMN}
                        </Text>
                      </Flex>

                      <Collapse in={!!collapsedPermissions[p.id]}>
                        <Stack pl="lg" gap={0}>
                          {Object.values(nodeObject)
                            .filter((i: any) => i.parent === p.id)
                            .map((child: any) => (
                              <Flex key={child.id} align="center" gap="sm" className={classes.item}>
                                <Checkbox checked={child.isChecked} onChange={(v) => handleToggle(child.id, v.currentTarget.checked)} disabled={isDetail} />
                                <Text size="sm">{child.nameMN}</Text>
                              </Flex>
                            ))}
                        </Stack>
                      </Collapse>
                    </div>
                  ))}
              </Collapse>
            </Stack>
          </Stack>
        ))}

      <Flex mt="lg">
        <Button onClick={submitRolePermission} disabled={isDetail}>
          Хадгалах
        </Button>
      </Flex>
    </Paper>
  );
};

export default RolePermission;

const useStyles = createStyles((theme) => ({
  item: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    gap: 10,
    borderRadius: theme.radius.xs,
    cursor: "pointer",
    borderBottom: "1px solid #dee2e6",
    "&:hover": {
      background: "rgb(255, 245, 245)",
    },
  },
}));
