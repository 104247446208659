import { Box, Button, Divider, Flex, Grid, InputWrapper, Popover, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { DehubUserApi } from "../../apis";
import { letters } from "../../constants/letter";
import { IDehubGeneral } from "../../interfaces/IGeneralDehub";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { ImageField } from "../form/image-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import RolePermission from "./role-permission";

type User = {
  avatar?: string | null;
  familyName: string;
  lastName: string;
  firstName: string;
  registerNo: string;
  phone: string;
  phone2?: string | null;
  email: string;
  email2?: string | null;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  type?: string;
  username?: string;
  regUserId?: string;
  userStatus?: string;
  registerStatus?: boolean;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
  isDanVerified?: boolean;
  hasPassword?: boolean;
  hasPin?: boolean;
  sessionId?: string;
  loginType?: string;
  roles?: any;
  permissions?: any;
  passwordChangeRequired?: boolean;
};
type Props = {
  user: User;
  isDetail: boolean;
  userId: string;
};

const schema = yup.object({
  avatar: yup.string().nullable().trim().optional(),
  familyName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!")
    .trim(),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!")
    .trim(),
  lastName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  registerNo: yup
    .string()
    .matches(/^[А-Яа-яA-Za-z|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Зөвхөн регистр оруулна уу")
    .required("Заавал бөглөнө!")
    .trim(),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .required("Заавал бөглөнө!")
    .nullable(),
  email: yup.string().email("Email оруулна уу").required("Заавал бөглөнө!").nullable().trim(),
  phone2: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .optional()
    .nullable(),
  email2: yup.string().email("Email оруулна уу").nullable(),
  userStatus: yup.string().required("Хэрэглэгчийн төлөв сонгоно уу"),
  passwordChangeRequired: yup.boolean().optional(),
  username: yup.string().required("Заавал бөглөнө!"),
});

const convertToObject = (items: any) => {
  return (items || []).reduce((acc: any, c: any) => {
    acc[`${c.id}`] = {
      ...c,
      permissions: undefined,
      parent: null,
    };

    c.permissions.forEach((permission: any) => {
      acc[`${permission.id}`] = {
        ...permission,
        parent: c.id,
      };
    });
    return acc;
  }, {});
};

const UserForm = ({ user, isDetail, userId }: Props) => {
  const { userStatus: userStatusOptions } = useSelector((state: { dehubGeneral: IDehubGeneral }) => state.dehubGeneral);
  const [selectedLetters, setSelectedLetters] = useState<string[]>(() => {
    return user?.registerNo ? [user.registerNo.slice(0, 1), user.registerNo.slice(1, 2)] : ["А", "А"];
  });

  const [number, setNumber] = useState<string>(() => user?.registerNo?.slice(2) || "");
  const [openedPopover, setOpenedPopover] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [data, setData] = useState<User>({
    avatar: "",
    familyName: "",
    firstName: "",
    lastName: "",
    registerNo: "",
    phone: "",
    email: "",
    userStatus: "",
    username: "",
    passwordChangeRequired: false,
  });

  useEffect(() => {
    if (user) {
      setData((prev) => ({
        ...prev,
        ...user,
      }));

      if (user.registerNo !== data.registerNo) {
        setSelectedLetters([user.registerNo.slice(0, 1), user.registerNo.slice(1, 2)]);
        setNumber(user.registerNo.slice(2) || "");
      }
    }
  }, [user, data.registerNo]);

  const handleLetterSelect = (index: number, letter: string, setFieldValue: any) => {
    setSelectedLetters((prevLetters) => {
      const updatedLetters = [...prevLetters];
      updatedLetters[index] = letter;
      setFieldValue("registerNo", `${updatedLetters[0]}${updatedLetters[1]}${number}`);
      return updatedLetters;
    });
  };

  const handleSubmit = async (values: User) => {
    setLoading(true);
    try {
      const {
        id,
        createdAt,
        updatedAt,
        type,
        regUserId,
        userStatus,
        registerStatus,
        isEmailVerified,
        isPhoneVerified,
        isDanVerified,
        hasPassword,
        hasPin,
        sessionId,
        loginType,
        roles,
        permissions,
        username,
        ...payload
      } = values;

      const updatePayload = {
        ...payload,
        status: userStatus,
      };

      const createPayload = {
        ...payload,
        username,
        passwordChangeRequired: false,
      };

      if (!isDetail && userId) {
        const res = await DehubUserApi.update(id!, updatePayload);
        if (res) {
          Message.success("Хэрэглэгчийн мэдээлэл амжилттай шинэчлэгдлээ");
        }
      } else {
        const res = await DehubUserApi.create(createPayload);
        if (res) {
          Message.success("Хэрэглэгч амжилттай бүртгэлээ");
        }
      }
    } catch (error: any) {
      Message.error(error?.message);
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  const mappedData = convertToObject(data?.roles || []);

  return (
    <>
      <Divider mb="lg" />
      <Form validationSchema={schema} onSubmit={handleSubmit} initialValues={data}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 3, md: 1 }}>
                <Flex justify="center" align="center" h="100%">
                  <ImageField name="avatar" label="Зураг" disabled={isDetail} />
                </Flex>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 9, md: 11 }}>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField name="familyName" placeholder="Ургийн овог оруулна уу" label="Ургийн овог" required disabled={isDetail} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField name="lastName" placeholder="Овог оруулна уу" label="Овог" required disabled={isDetail} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField name="firstName" placeholder="Нэрээ оруулна уу" label="Нэр" required disabled={isDetail} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <InputWrapper label="Регистрийн дугаар" required>
                      <Flex gap="xs">
                        {selectedLetters.map((letter, index) => (
                          <Popover
                            key={index}
                            opened={openedPopover === index}
                            onClose={() => setOpenedPopover(null)}
                            onChange={(opened) => {
                              if (!opened) setOpenedPopover(null);
                            }}>
                            <Popover.Target>
                              <Button
                                style={{ width: 40, height: 36, padding: 0, border: errors?.registerNo && !values.registerNo ? `1px solid red` : `` }}
                                onClick={() => setOpenedPopover(index)}
                                variant="default"
                                disabled={isDetail}>
                                {letter || "А"}
                              </Button>
                            </Popover.Target>
                            <Popover.Dropdown w={264} style={{ padding: 10 }}>
                              <Box style={{ display: "flex", alignItems: "center", gap: 10, flexWrap: "wrap" }}>
                                {letters.map((l) => (
                                  <Button
                                    key={l}
                                    style={{ padding: 0, width: 40, height: 40 }}
                                    onClick={() => {
                                      handleLetterSelect(index, l, setFieldValue);
                                      setOpenedPopover(null);
                                    }}
                                    variant={selectedLetters[index] === l ? "filled" : "default"}
                                    disabled={isDetail}>
                                    {l}
                                  </Button>
                                ))}
                              </Box>
                            </Popover.Dropdown>
                          </Popover>
                        ))}
                        <TextInput
                          placeholder="Оруулна уу"
                          name="registerNo"
                          onChange={(e: any) => {
                            setFieldValue("registerNo", `${selectedLetters[0]}${selectedLetters[1]}${e.target.value.replace(/\D/, "")}`);
                            setNumber(e.target.value.replace(/\D/, ""));
                          }}
                          value={number}
                          styles={{ input: { borderColor: errors?.registerNo && !values.registerNo ? "red" : "" } }}
                          style={{ flex: 1 }}
                          disabled={isDetail}
                        />
                      </Flex>
                      {errors?.registerNo && !values.registerNo && (
                        <Text size="xs" c="red" mt={5}>
                          {errors.registerNo}
                        </Text>
                      )}
                    </InputWrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField name="email" placeholder="Цахим шуудан оруулна уу" label="Цахим шуудан" required disabled={isDetail} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField name="phone" placeholder="Утас оруулна уу" label="Утас" required disabled={isDetail} styles={{ root: { flex: 1 } }} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <SelectField
                      label="Төлөв"
                      name="userStatus"
                      placeholder="Төлөв сонгоно уу"
                      options={(userStatusOptions || []).map((us: any) => ({ label: us.name, value: us.code }))}
                      disabled={isDetail}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField
                      label="Хэрэглэгчийн нэр"
                      name="username"
                      placeholder="Хэрэглэгчийн нэр оруулна уу"
                      disabled={isDetail || userId !== ""}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <InputWrapper label="Нууц үг солих шаардлагатай эсэх">
                      <CheckboxField name="passwordChangeRequired" disabled={isDetail}>
                        {values.passwordChangeRequired ? "Тийм" : "Үгүй"}
                      </CheckboxField>
                    </InputWrapper>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <Flex justify="end" gap="md">
                  <Button type="submit" loading={loading} disabled={loading || isDetail}>
                    Хадгалах
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>
      <Divider my="lg" />
      {data?.roles && data?.roles.length > 0 && <RolePermission data={mappedData} userId={userId || userId} isDetail={isDetail} />}
    </>
  );
};

export default UserForm;
