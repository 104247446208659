import { Button, Flex, InputWrapper } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { RoleApi } from "../../apis";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";

const schema = yup.object({
  nameMN: yup.string().required("Заавал бөглөнө үү"),
  description: yup.string().required("Заавал бөглөнө үү"),
});

type FormValues = {
  nameMN: string;
  description: string;
};

const RoleForm = ({ onClose }: { onClose: () => void }) => {
  const [initialValues] = useState({
    nameMN: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const res = await RoleApi.create(values);
      if (res) {
        setLoading(false);
        Message.success("Роль амжиллтай үүслээ");
        navigate("/users/role");
      }
    } catch (error: any) {
      Message.error(error?.message || "Хүсэлт амжилтгүй");
      setLoading(false);
    }
  };

  return (
    <Form initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {() => {
        return (
          <Flex direction="column" gap="lg">
            <InputWrapper label="Ролийн нэр" required>
              <TextField name="nameMN" placeholder={""} />
            </InputWrapper>

            <InputWrapper label="Тайлбар" required>
              <TextareaField name="description" placeholder={""} label={""} />
            </InputWrapper>

            <Flex gap="md" justify="end">
              <Button type="button" variant="outline" onClick={onClose}>
                Болих
              </Button>
              <Button type="submit" disabled={loading}>
                Хадгалах
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
};

export default RoleForm;
