import { ActionIcon, Avatar, Badge, Box, Flex, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconCircleCheck, IconCircleMinus, IconEye, IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoleApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import { Message } from "../../utils/message";
import { ColumnType, Table } from "../table";

const RoleList = () => {
  const navigate = useNavigate();
  const [dataUpdated, setDataUpdated] = useState(false);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          navigate(`/users/role/${item?.id}`);
          break;
        }
        case "active": {
          openConfirmModal({
            title: `${item.roleStatus === "ACTIVE" ? "Роль идэвхгүй болгох" : "Роль идэвхжүүлэх"}`,
            children: <Text>Хэрэглэгчийн ролийг {item.roleStatus === "ACTIVE" ? "идэвхгүй" : "идэвхтэй"} болгох уу ?</Text>,
            labels: { confirm: `${item.roleStatus === "ACTIVE" ? "Идэвхгүй болгох" : "Идэвхжүүлэх"}`, cancel: "Болих" },
            onConfirm: async () => {
              try {
                const res = await RoleApi.active(item.id, item.roleStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE");
                if (res) {
                  Message.success("Үйлдэл амжилттай");
                  setDataUpdated((prev) => !prev);
                }
              } catch (error: any) {
                Message.error(error?.message);
              }
            },
            centered: true,
          });
          break;
        }
      }
    },
  });

  return <Table name="user-roles.list" columns={columns} loadData={(data) => RoleApi.list(data!)} key={dataUpdated ? "data-updated" : "data"} />;
};

export default RoleList;

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="xs">
          <Tooltip label="Дэлгэрэнгүй" position="top">
            <ActionIcon variant="light" onClick={() => onClick("detail", record ?? "-")}>
              <IconEye />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={record?.roleStatus === "ACTIVE" ? "Идэвхгүй болгох" : "Идэвхтэй болгох"} position="top">
            <ActionIcon variant="light" onClick={() => onClick("active", record ?? "-")}>
              {record?.roleStatus === "ACTIVE" ? <IconCircleMinus /> : <IconCircleCheck />}
            </ActionIcon>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "nameMN",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMN ?? "-"}
      </Text>
    ),
  },
  {
    title: "Эрхийн төлөв",
    dataIndex: "roleStatus",
    render: (record) => (
      <Badge color={record?.roleStatus === "ACTIVE" ? "green.5" : "red.5"} radius="xs" variant="outline">
        {record?.roleStatus === "ACTIVE" ? "Идэвхтэй" : "Идэвхгүй"}
      </Badge>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => <Text size="sm">{record?.description ?? "-"}</Text>,
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "regUser",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.regUser?.avatar ?? "-"}>
          <IconPhoto />
        </Avatar>
        <Box>
          <Text size="sm">{record?.regUser?.lastName ?? "-"}</Text>
          <Text size="sm">{record?.regUser?.firstName ?? "-"}</Text>
        </Box>
      </Flex>
    ),
  },
  {
    title: "Хэрэглэгчийн тоо",
    dataIndex: "userCount",
    render: (record) => <Text size="sm">{record?.userCount ?? 0}</Text>,
  },
  {
    title: "Үүссэн огноо",
    dataIndex: "createdAt",
    render: (record) => (
      <Text size="sm" w="max-content">
        {dateTimeFormat(record?.createdAt ?? "-")}
      </Text>
    ),
  },
];
