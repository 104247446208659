import { ActionIcon, Button, Center, Checkbox, Collapse, Divider, Flex, Grid, InputWrapper, Paper, Select, Stack, Text, TextInput } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useForm, yupResolver } from "@mantine/form";
import { IconBox, IconChevronDown, IconChevronUp, IconKey, IconLayoutDashboard, IconMenu2 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PermissionApi } from "../../apis";
import { IDehubGeneral } from "../../interfaces/IGeneralDehub";
import { Message } from "../../utils/message";

const schema = Yup.object().shape({
  nameMN: Yup.string().required("Заавал бөглөнө үү"),
  code: Yup.string().required("Заавал бөглөнө үү"),
});

type TreeProps = {
  mergedData: any;
  permission: any;
  isDetail: boolean;
  permissionId: string;
};

const CreateTee = ({ mergedData, permission, isDetail, permissionId }: TreeProps) => {
  const [openedSections, setOpenedSections] = useState<{ [key: string]: boolean }>({});
  const { permissionStatus } = useSelector((state: { dehubGeneral: IDehubGeneral }) => state.dehubGeneral);
  const [nodeObject, setNodeObject] = useState<any>({});
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    setNodeObject(mergedData);
  }, [mergedData]);

  const form = useForm({
    initialValues: {
      nameMN: "",
      code: "",
      modules: [{}],
      status: "",
    },
    validate: yupResolver(schema),
  });

  useEffect(() => {
    if (permission) {
      form.setFieldValue("nameMN", permission.nameMN || "");
      form.setFieldValue("code", permission.code || "");
      form.setFieldValue("modules", permission.modules || []);
      form.setFieldValue("status", permission.status || "");
    }
  }, [permission]);

  const findParents = (key: string, isChecked: boolean) => {
    if (!key) return [];
    const parent: any = Object.values(nodeObject).find((i: any) => i.key === key);
    let data = undefined;

    const checkOtherChilds = Object.values(nodeObject).filter((i: any) => i.parent === key && i.isChecked === true);

    if (parent) {
      if (!isChecked && checkOtherChilds && checkOtherChilds.length > 1) return;

      data = [parent];
      const granParent: any = findParents(parent.parent, isChecked);
      if (granParent) {
        data = [...data, ...granParent];
      }
    }
    return data;
  };

  const findChildren = (keys: string[]) => {
    const children: any = Object.values(nodeObject).filter((i: any) => keys.includes(i.parent));
    let data: any = undefined;

    console.log("children:::>", children);

    if (children && children.length) {
      data = [...children];
      const childs: any = findChildren(children.map((e: any) => e.key));
      if (childs) {
        data = [...data, ...childs];
      }
    }

    return data;
  };

  const handleToggle = (key: string, isChecked: boolean) => {
    const parents = findParents(nodeObject[key].parent, isChecked) || [];
    const childs = findChildren([nodeObject[key].key]) || [];
    setNodeObject((prev: any) => ({
      ...prev,
      [key]: {
        ...prev[key],
        isChecked,
      },
      ...parents.reduce((acc, c) => {
        acc[c.key] = { ...c, isChecked };
        return acc;
      }, {}),

      ...childs.reduce((acc: any, c: any) => {
        acc[c.key] = { ...c, isChecked };
        return acc;
      }, {}),
    }));
  };

  const toggleCollapse = (sectionKey: string) => {
    setOpenedSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  const handleSubmit = async () => {
    const validation = form.validate();
    if (validation.hasErrors) {
      return;
    } else {
      const formValue = Object.values(nodeObject);
      const selectedModules = formValue.filter((i: any) => i.parent === null && i.isChecked === true);

      const payload = {
        nameMN: form.values.nameMN,
        code: form.values.code,
        modules: selectedModules.map((module: any) => {
          return {
            code: module.code,
            menus: formValue
              .filter((i: any) => i.parent === module.key && i.isChecked === true)
              .map((menu: any) => {
                return {
                  code: menu.code,
                  views: formValue
                    .filter((i: any) => i.parent === menu.key && i.isChecked === true)
                    .map((view: any) => {
                      return {
                        code: view.code,
                        actions: formValue.filter((a: any) => a.parent === view.key && a.isChecked === true).map((action: any) => action.code),
                      };
                    }),
                };
              }),
          };
        }),
      };

      try {
        if (!isDetail && permissionId) {
          const res = await PermissionApi.update({
            ...payload,
            id: permissionId,
            status: form.values.status,
          });
          if (res) {
            Message.success("Эрх амжилттай үүслээ");
            navigate("/users/permissions");
          }
        } else {
          const res = await PermissionApi.create(payload);
          if (res) {
            Message.success("Эрх амжилттай үүслээ");
            navigate("/users/permissions");
          }
        }
      } catch (error: any) {
        Message.error(error?.message);
      }
    }
  };

  const nodeData = Object.values(nodeObject);
  const modules = nodeData.filter((i: any) => i.parent === null);

  return (
    <Paper p="lg" withBorder>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid mb="sm">
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <InputWrapper label="Эрхийн нэр" required>
              <TextInput {...form.getInputProps("nameMN")} disabled={isDetail} />
            </InputWrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <InputWrapper label="Эрхийн код" required>
              <TextInput {...form.getInputProps("code")} disabled={isDetail} />
            </InputWrapper>
          </Grid.Col>
          {permissionId && (
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <InputWrapper label="Төлөв" required>
                <Select
                  data={
                    permissionStatus?.map((ps) => ({
                      value: ps.code,
                      label: ps.name,
                    })) || []
                  }
                  {...form.getInputProps("status")}
                  disabled={isDetail}
                />
              </InputWrapper>
            </Grid.Col>
          )}
        </Grid>
        <Flex align="center" gap="xs" mb="sm">
          <ActionIcon variant="light">
            <IconBox size={16} />
          </ActionIcon>
          <Text size="md" fw={600}>
            Модулиуд
          </Text>
        </Flex>
        {modules.map((module: any, moduleIndex) => (
          <Stack key={module.key} gap="0">
            <Flex
              align="center"
              justify="space-between"
              className={classes.item}
              style={{ borderBottom: modules.length - 1 === moduleIndex ? "0" : undefined }}>
              <Flex align="center" gap="xs" style={{ flex: 1 }}>
                <Checkbox
                  {...form.getInputProps(`modules.${moduleIndex}.isChecked`, { type: "checkbox" })}
                  checked={module.isChecked || false}
                  onChange={(e) => handleToggle(module.key, e.target.checked)}
                  disabled={isDetail}
                />
                <Text size="sm" style={{ cursor: "pointer", flex: 1 }} onClick={() => toggleCollapse(`module-${module.code}`)}>
                  {module.nameMN}
                </Text>
              </Flex>
              <ActionIcon variant="light" size={20} onClick={() => toggleCollapse(`module-${module.code}`)}>
                {openedSections[`module-${module.code}`] ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
              </ActionIcon>
            </Flex>

            {/* Collapse for menus */}
            <Collapse in={openedSections[`module-${module.code}`]}>
              <Flex align="center" gap="xs" my="xs" pl="lg">
                <ActionIcon variant="light" size={20}>
                  <IconMenu2 size={16} />
                </ActionIcon>
                <Text size="md" fw={600}>
                  Цэснүүд
                </Text>
              </Flex>
              <Stack gap="0" pl="lg">
                {nodeData.filter((i: any) => i.parent === `${moduleIndex}#${module.code}`).length > 0 ? (
                  (nodeData.filter((i: any) => i.parent === `${moduleIndex}#${module.code}`) || []).map((menu: any, menuIndex) => (
                    <div key={menu.key}>
                      <Flex align="center" justify="space-between" className={classes.item}>
                        <Flex align="center" gap="xs" style={{ flex: 1 }}>
                          <Checkbox
                            {...form.getInputProps(`modules.${moduleIndex}.menus.${menuIndex}.isChecked`, { type: "checkbox" })}
                            checked={menu.isChecked || false}
                            onChange={(e) => handleToggle(menu.key, e.target.checked)}
                            disabled={isDetail}
                          />
                          <Text size="sm" style={{ flex: 1 }} onClick={() => toggleCollapse(`menu-${menu.code}`)}>
                            {menu.nameMN}
                          </Text>
                        </Flex>
                        <ActionIcon variant="light" size={20} onClick={() => toggleCollapse(`menu-${menu.code}`)}>
                          {openedSections[`menu-${menu.code}`] ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
                        </ActionIcon>
                      </Flex>

                      {/* Collapse for views */}
                      <Collapse in={openedSections[`menu-${menu.code}`]}>
                        <Flex align="center" gap="xs" my="xs" pl="xl">
                          <ActionIcon variant="light" size={20}>
                            <IconLayoutDashboard size={16} />
                          </ActionIcon>
                          <Text size="md" fw={600}>
                            Цонхнууд
                          </Text>
                        </Flex>
                        <Stack gap="0" pl="xl">
                          {nodeData.filter((i: any) => i.parent === `${module.code}#${menu.code}`).length > 0 ? (
                            (nodeData.filter((i: any) => i.parent === `${module.code}#${menu.code}`) || []).map((view: any, viewIndex) => (
                              <div key={view.key}>
                                <Flex align="center" justify="space-between" className={classes.item}>
                                  <Flex align="center" gap="xs" style={{ flex: 1 }}>
                                    <Checkbox
                                      {...form.getInputProps(`modules.${moduleIndex}.menus.${menuIndex}.views.${viewIndex}.isChecked`, { type: "checkbox" })}
                                      checked={view.isChecked || false}
                                      onChange={(e) => handleToggle(view.key, e.target.checked)}
                                      disabled={isDetail}
                                    />
                                    <Text size="sm" style={{ flex: 1 }} onClick={() => toggleCollapse(`view-${view.code}`)}>
                                      {view.nameMN}
                                    </Text>
                                  </Flex>
                                  <ActionIcon variant="light" size={20} onClick={() => toggleCollapse(`view-${view.code}`)}>
                                    {openedSections[`view-${view.code}`] ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
                                  </ActionIcon>
                                </Flex>

                                {/* Collapse for actions */}
                                <Collapse in={openedSections[`view-${view.code}`]}>
                                  <Flex align="center" gap="xs" my="xs" pl="lg">
                                    <ActionIcon variant="light" size={20}>
                                      <IconKey size={16} />
                                    </ActionIcon>
                                    <Text size="md" fw={600}>
                                      Үйлдлүүд
                                    </Text>
                                  </Flex>
                                  <Stack gap="0" pl="lg">
                                    {(nodeData.filter((i: any) => i.parent === `${module.code}#${menu.code}#${view.code}`) || []).map(
                                      (action: any, actionIndex) => (
                                        <Flex key={action.key} align="center" gap="xs" className={classes.item}>
                                          <Checkbox
                                            {...form.getInputProps(
                                              `modules.${moduleIndex}.menus.${menuIndex}.views.${viewIndex}.actions.${actionIndex}.isChecked`,
                                              { type: "checkbox" },
                                            )}
                                            checked={action.isChecked || false}
                                            onChange={(e) => handleToggle(action.key, e.target.checked)}
                                            disabled={isDetail}
                                          />
                                          <Text size="sm">{action.nameMN}</Text>
                                        </Flex>
                                      ),
                                    )}
                                  </Stack>
                                </Collapse>
                              </div>
                            ))
                          ) : (
                            <Center sx={(theme) => ({ padding: theme.spacing.md })}>
                              <Text size="sm" c="gray">
                                Өгөгдөл өлдсонгүй
                              </Text>
                            </Center>
                          )}
                        </Stack>
                      </Collapse>
                    </div>
                  ))
                ) : (
                  <Center sx={(theme) => ({ padding: theme.spacing.md })}>
                    <Text size="sm" c="gray">
                      Өгөгдөл өлдсонгүй
                    </Text>
                  </Center>
                )}
              </Stack>
            </Collapse>
          </Stack>
        ))}
        <Divider my="lg" />
        <Flex justify="start">
          <Button type="submit" disabled={isDetail}>
            Хадгалах
          </Button>
        </Flex>
      </form>
    </Paper>
  );
};

const convertToObject = (modules: any) => {
  /* Modules */
  return (modules || []).reduce((acc: any, module: any, index: number) => {
    acc[`${index}#${module.code}`] = {
      ...module,
      parent: null,
      key: `${index}#${module.code}`,
    };

    /* Menus */
    (module.menus || []).forEach((menu: any) => {
      acc[`${module.code}#${menu.code}`] = {
        ...menu,
        key: `${module.code}#${menu.code}`,
        parent: `${index}#${module.code}`,
      };

      /* Views */
      (menu.views || []).forEach((view: any) => {
        acc[`${module.code}#${menu.code}#${view.code}`] = {
          ...view,
          key: `${module.code}#${menu.code}#${view.code}`,
          parent: `${module.code}#${menu.code}`,
        };

        /* Actions */
        (view.actions || []).forEach((action: any) => {
          acc[`${module.code}#${menu.code}#${view.code}#${action.code}`] = {
            ...action,
            key: `${module.code}#${menu.code}#${view.code}#${action.code}`,
            parent: `${module.code}#${menu.code}#${view.code}`,
          };
        });
      });
    });

    return acc;
  }, {});
};

type PermissionFormProps = {
  permission: any;
  isDetail: boolean;
  permissionId: string;
};

const PermissionForm = ({ permission, isDetail, permissionId }: PermissionFormProps) => {
  const { modules } = useSelector((state: { dehubGeneral: IDehubGeneral }) => state.dehubGeneral);
  const allPermissions = convertToObject(modules);

  const permissionData = convertToObject(permission?.modules) || {};

  Object.keys(allPermissions).forEach((e) => {
    allPermissions[e] = {
      ...allPermissions[e],
      isChecked: permissionData[e] !== undefined,
    };
  });

  return <CreateTee mergedData={allPermissions} permission={permission} isDetail={isDetail} permissionId={permissionId} />;
};

export default PermissionForm;

const useStyles = createStyles((theme) => ({
  item: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.xs,
    cursor: "pointer",
    borderBottom: "1px solid #dee2e6",
    "&:hover": {
      background: "rgb(255, 245, 245)",
    },
  },
}));
