import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/roles", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/role/${id}`);
};

export const create = async (data: any) => {
  return httpRequest.post(`/api/role`, data);
};

export const addPermission = async (data: any) => {
  return httpRequest.post(`/api/role/permission`, data);
};

export const active = async (id: string, status: string) => {
  return httpRequest.put(`/api/role/status/${id}`, { status: status });
};
