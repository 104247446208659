import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Grid,
  InputWrapper,
  LoadingOverlay,
  Modal,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  IconArrowLeft,
  IconArrowRight,
  IconBox,
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconDatabaseOff,
  IconKey,
  IconMenu2,
  IconMinus,
  IconPhoto,
  IconPlus,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { RoleApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { dateTimeFormat } from "../../utils/date";
import { Message } from "../../utils/message";

const RoleDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [role, setRole] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchRole = async () => {
    if (id) {
      try {
        const response = await RoleApi.get(id);
        setRole(response);
      } catch (error: any) {
        Message.error(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRole();
  }, [id]);

  return (
    <PageLayout
      title={
        <Flex align="center" gap="xs">
          <ActionIcon variant="light" onClick={() => navigate("/users/role")} size={38}>
            <IconArrowLeft />
          </ActionIcon>
          <Box>
            <Text size="md" fw={600}>
              Ролийн дэлгэрэнгүй
            </Text>
            <Text size="sm" c="gray">
              Систем дэр ролийн дэлгэрэнгүй задаргаа
            </Text>
          </Box>
        </Flex>
      }>
      <LoadingOverlay visible={loading} />
      <Divider mb="md" />
      <Grid>
        <Grid.Col span={{ xs: 24, sm: 4, md: 4 }}>
          <InputWrapper label="Ролийн нэр:">
            <Text size="sm" mt="sm">
              {role?.nameMN}
            </Text>
          </InputWrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 24, sm: 4, md: 4 }}>
          <InputWrapper label="Үүсгэсэн">
            <Flex align="center" gap="xs" mt="sm">
              <Avatar src={role?.regUser?.avatar} size={32}>
                <IconPhoto />
              </Avatar>
              <Text size="sm">
                {role?.regUser?.lastName} {role?.regUser?.firstName}
              </Text>
            </Flex>
          </InputWrapper>
        </Grid.Col>
        <Grid.Col span={{ xs: 24, sm: 4, md: 4 }}>
          <InputWrapper label="Үүссэн огноо:">
            <Text size="sm" mt="sm">
              {dateTimeFormat(role?.createdAt ?? "-")}
            </Text>
          </InputWrapper>
        </Grid.Col>
      </Grid>
      <Divider mb="lg" mt="lg" />
      {role?.permission && <PermissionCollapse data={role?.permission} roleId={id || ""} fetchRole={fetchRole} />}
    </PageLayout>
  );
};

export default RoleDetail;

function PermissionCollapse({ data, roleId, fetchRole }: { data: any; roleId: string; fetchRole: any }) {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<{}>(
    data.reduce((acc: any, c: any) => {
      acc[`${c.id}`] = c;
      return acc;
    }, {}),
  );

  const handleCheckboxChange = (permission: any, isChecked: boolean) => {
    setPermissions((prev: any) => ({
      ...prev,
      [permission.id]: {
        ...prev[`${permission.id}`],
        isChecked,
      },
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      roleId,
      permissionCodes: Object.values(permissions)
        .filter((e: any) => e.isChecked)
        .map((sp: any) => sp.code),
    };

    try {
      const res = await RoleApi.addPermission(payload);
      if (res) {
        Message.success("Эрх амжилттай нэмлээ");
        setLoading(false);
        fetchRole();
      }
    } catch (error: any) {
      Message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Stack gap="0">
        <Flex align="center" gap="md" mb="md">
          <Flex align="center" gap="xs">
            <ActionIcon variant="light" size={20}>
              <IconKey size={16} />
            </ActionIcon>
            <Text size="md" fw={600}>
              Эрхүүд
            </Text>
          </Flex>
        </Flex>

        {Object.values(permissions).map((permission: any) => (
          <PermissionItem key={permission.code} permission={permission} handleCheckboxChange={handleCheckboxChange} />
        ))}

        <Divider my="md" />

        <Box>
          <Button loading={loading} onClick={handleSubmit}>
            Хадгалах
          </Button>
        </Box>
      </Stack>
    </>
  );
}

function PermissionItem({ permission, handleCheckboxChange }: { permission: any; handleCheckboxChange: (permissionCode: string, value: boolean) => void }) {
  const [opened, setOpened] = useState(false);
  return (
    <Stack gap="xs">
      <div
        style={{
          textAlign: "left",
          padding: "0.8rem",
          borderRadius: 5,
          borderBottom: "1px solid #dee2e6",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          background: opened ? "rgb(255, 245, 245)" : "",
          transition: "background 0.3ms ease-in-out",
          justifyContent: "space-between",
        }}>
        <Flex align="center" gap="sm" style={{ flex: 1 }}>
          <Checkbox checked={permission.isChecked ?? false} onChange={(v) => handleCheckboxChange(permission, v.currentTarget.checked)} />
          <Text onClick={() => setOpened((o) => !o)} style={{ flex: 1 }} size="sm">
            {permission.nameMN}
          </Text>
        </Flex>

        <ActionIcon variant="light" size={20} onClick={() => setOpened((o) => !o)}>
          {!opened ? <IconChevronDown size={16} /> : <IconChevronUp size={16} />}
        </ActionIcon>
      </div>
      <Collapse in={opened}>
        <Stack pl="xl" gap={0}>
          <Flex align="center" gap="xs" mb="sm">
            <ActionIcon variant="light">
              <IconBox size={16} />
            </ActionIcon>
            <Text size="md" fw={600}>
              Модулиуд
            </Text>
          </Flex>
          {permission?.modules?.length > 0 ? (
            permission?.modules?.map((module: any, index: number) => <ModuleItem key={`${index}#${module.code}`} module={module} />)
          ) : (
            <Center sx={(theme) => ({ padding: theme.spacing.md })} style={{ flexDirection: "column", gap: 10 }}>
              <IconDatabaseOff size={16} color="gray" />
              <Text ta="center" size="sm">
                Өгөгдөл олдсонгүй
              </Text>
            </Center>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}

function ModuleItem({ module }: { module: any }) {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();

  return (
    <Stack
      style={{
        borderLeft: opened ? `1px solid #dee2e6` : `1px solid transparent`,
        borderBottom: opened ? `1px solid #dee2e6` : `1px solid transparent`,
        paddingBottom: opened ? `2rem` : "",
      }}>
      <div className={classes.moduleItem} onClick={() => setOpened((o) => !o)} style={{ background: opened ? "rgb(255, 245, 245)" : "" }}>
        <ActionIcon variant="light" size={20}>
          {!opened ? <IconPlus size={16} /> : <IconMinus size={16} />}
        </ActionIcon>
        <Text size="sm">{module.nameMN}</Text>
      </div>
      <Collapse in={opened}>
        <Stack gap="0" pl="xl">
          <Flex align="center" gap="xs" mb="xs">
            <ActionIcon variant="light" size={20}>
              <IconMenu2 size={16} />
            </ActionIcon>
            <Text size="md" fw={600}>
              Цэснүүд
            </Text>
          </Flex>

          {module?.menus?.length > 0 ? (
            module?.menus?.map((menu: any, index: number) => <Menus key={`${index}#${menu.code}`} menu={menu} />)
          ) : (
            <Center sx={(theme) => ({ padding: theme.spacing.md })} style={{ flexDirection: "column", gap: 10 }}>
              <IconDatabaseOff size={16} color="gray" />
              <Text ta="center">Өгөгдөл олдсонгүй</Text>
            </Center>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}

function Menus({ menu }: { menu: any }) {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();
  const [selectedView, setSelectedView] = useState<any>(null);
  const [selectedTitle, setSelectedTitle] = useState<string>("");
  return (
    <Stack>
      <div
        className={classes.menuItem}
        onClick={() => {
          setSelectedTitle(menu.nameMN);
          setOpened(true);
        }}>
        {menu.nameMN}
        <ActionIcon variant="light" size={20}>
          <IconChevronRight size={16} />
        </ActionIcon>
      </div>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          setSelectedView(null);
          setSelectedTitle("");
        }}
        centered
        title={selectedTitle}
        size="60rem"
        padding="xl">
        <Flex gap="lg">
          <Box style={{ flex: 1 }}>
            <Text size="sm" fw={600} mb="sm">
              Цонхнууд
            </Text>
            <Box style={{ flex: 1, border: `1px solid #dee2e6`, minHeight: 400, maxHeight: 400, overflowY: "auto" }}>
              <Table striped verticalSpacing="sm" horizontalSpacing="md">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th w="50%">Код</Table.Th>
                    <Table.Th w="50%">Нэр</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {menu?.views?.length > 0 ? (
                    menu?.views?.map((view: any) => (
                      <Table.Tr
                        key={view.code}
                        onClick={() => setSelectedView(view)}
                        style={{
                          cursor: "pointer",
                          background: view?.code === selectedView?.code ? "#ededed" : "",
                          transition: "background 0.3s linear",
                        }}>
                        <Table.Td>
                          <Text size="sm">{view.code}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text size="sm">{view.nameMN}</Text>
                        </Table.Td>
                      </Table.Tr>
                    ))
                  ) : (
                    <Table.Tr>
                      <Table.Td colSpan={2}>
                        <Center>
                          <Text size="sm" c="gray">
                            Өгөгдөл олдсонгүй
                          </Text>
                        </Center>
                      </Table.Td>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>
            </Box>
          </Box>
          <IconArrowRight style={{ alignSelf: "center" }} />
          <Box style={{ flex: 1 }}>
            <Text size="sm" fw={600} mb="sm">
              Үйлдлүүд
            </Text>
            <Box style={{ flex: 1, border: `1px solid #dee2e6`, minHeight: 400, maxHeight: 400, overflowY: "auto" }}>
              <Table striped verticalSpacing="sm" horizontalSpacing="md">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th w="50%">Код</Table.Th>
                    <Table.Th w="50%">Нэр</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                {selectedView?.actions && (
                  <Table.Tbody>
                    {selectedView.actions.length > 0 ? (
                      selectedView.actions.map((action: any) => (
                        <Table.Tr key={action.code}>
                          <Table.Td>
                            <Text size="sm">{action.code}</Text>
                          </Table.Td>
                          <Table.Td>
                            <Text size="sm">{action.nameMN}</Text>
                          </Table.Td>
                        </Table.Tr>
                      ))
                    ) : (
                      <Table.Tr>
                        <Table.Td colSpan={2}>
                          <Center>
                            <Text size="sm" c="gray">
                              Өгөгдөл олдсонгүй
                            </Text>
                          </Center>
                        </Table.Td>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                )}
              </Table>
            </Box>
          </Box>
        </Flex>
      </Modal>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  moduleItem: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    gap: 10,
    borderRadius: theme.radius.xs,
    cursor: "pointer",
    borderBottom: "1px solid #dee2e6",
    "&:hover": {
      background: "rgb(255, 245, 245)",
    },
  },
  menuItem: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: theme.radius.xs,
    cursor: "pointer",
    borderBottom: "1px solid #dee2e6",
    "&:hover": {
      background: "rgb(255, 245, 245)",
    },
  },
  windowItem: {
    padding: theme.spacing.sm,
    border: `1px solid #dee2e6`,
    borderRadius: theme.radius.sm,
    cursor: "pointer",
    "&:hover": {
      background: "rgb(255, 245, 245)",
    },
  },
}));
