import { ActionIcon, Badge, Button, Flex, Text, TextInput, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconCircleCheck, IconCircleMinus, IconEdit, IconEye, IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PermissionApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import { Message } from "../../utils/message";
import { ColumnType, Table } from "../table";

const PermissionList = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>("");
  const [queryDebounced] = useDebouncedValue(query, 400);
  const [dataUpdated, setDataUpdated] = useState(false);

  const columns = useHeader({
    onClick: (key: any, record: any) => {
      switch (key) {
        case "edit": {
          navigate(`/users/permissions/${record?.id}/update?mode=${key}`, {
            state: { isDetail: key === "detail" },
          });
          break;
        }
        case "detail": {
          navigate(`/users/permissions/${record?.id}/detail?mode=${key}`, {
            state: { isDetail: key === "detail" },
          });
          break;
        }
        case "active": {
          openConfirmModal({
            title: `${record.status === "ACTIVE" ? "Эрх идэвхгүй болгох" : "Эрх идэвхжүүлэх"}`,
            children: <Text>Хэрэглэгчийн эрхийг {record.status === "ACTIVE" ? "идэвхгүй" : "идэвхтэй"} болгох уу ?</Text>,
            labels: { confirm: `${record.status === "ACTIVE" ? "Идэвхгүй болгох" : "Идэвхжүүлэх"}`, cancel: "Болих" },
            onConfirm: async () => {
              try {
                const res = await PermissionApi.active(record.id, record?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE");
                if (res) {
                  Message.success("Үйлдэл амжилттай");
                  setDataUpdated((prev) => !prev);
                }
              } catch (error: any) {
                Message.error(error?.message);
                setDataUpdated(false);
              }
            },
            centered: true,
          });
          break;
        }
      }
    },
  });

  return (
    <Flex direction="column" gap="lg">
      <Flex align="center" justify="space-between">
        <TextInput leftSection={<IconSearch />} placeholder="Хайх" onChange={(e) => setQuery(e.target.value)} w={300} />
        <Button onClick={() => navigate("/users/permissions/create")}>Эрх нэмэх</Button>
      </Flex>
      <Table
        name="permission.list"
        columns={columns}
        filters={{ query: queryDebounced, status: "" }}
        loadData={(data) => PermissionApi.list(data!)}
        key={dataUpdated ? "data-updated" : "data"}
      />
    </Flex>
  );
};

export default PermissionList;

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Flex gap="xs">
        <Tooltip label="Засах">
          <ActionIcon variant="light" onClick={() => onClick("edit", record)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Дэлгэрэнгүй">
          <ActionIcon variant="light" onClick={() => onClick("detail", record)}>
            <IconEye />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={record?.status === "ACTIVE" ? "Идэвхгүй болгох" : "Идэвхтэй болгох"} position="top">
          <ActionIcon variant="light" onClick={() => onClick("active", record ?? "-")}>
            {record?.status === "ACTIVE" ? <IconCircleMinus /> : <IconCircleCheck />}
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  },
  {
    title: "Код",
    render: (record) => <Text size="sm">{record?.code || "-"}</Text>,
  },
  {
    title: "Нэр",
    render: (record) => <Text size="sm">{record?.nameMN || "-"}</Text>,
  },
  {
    title: "Төлөв",
    render: (record) => (
      <Badge variant="outline" radius="sm" color={record?.status === "ACTIVE" ? "green.5" : "red.5"}>
        {record?.status === "ACTIVE" ? "Идэвхтэй" : "Идэвхгүй"}
      </Badge>
    ),
  },
  {
    title: "Үүссэн огноо",
    render: (record) => <Text size="sm">{dateTimeFormat(record?.createdAt)}</Text>,
  },
];
