import { Button, CloseButton, Divider, Flex, LoadingOverlay } from "@mantine/core";
import React from "react";
import useSWR from "swr";
import * as yup from "yup";
import { categoryApi, itemTypeApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { IItemType } from "../../interfaces/IItemType";
import { HttpHandler } from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  itemTypeId: yup.string().nullable().required("Заавал бөглөнө!"),
  name: yup.string().nullable().required("Заавал бөглөнө!"),
  classificationId: yup
    .string()
    .nullable()
    .when("check", (check, schema) => {
      return !check ? schema.required("Заавал бөглөнө!") : schema;
    }),
  subClassificationId: yup
    .string()
    .nullable()
    .when("check", (check, schema) => {
      return !check ? schema.required("Заавал бөглөнө!") : schema;
    }),
  description: yup.string().nullable().max(255, "Хэт урт байна!").required("Заавал бөглөнө!"),
});

type IFormData = {
  itemTypeId: string;
  classificationId: string;
  subClassificationId: string;
  name: string;
  description: string;
  check: boolean;
  isPublic: boolean;
  type: string;
};

export function CategoryForm({ onCancel, action, reload }: { onCancel?: () => void; action: any; reload: () => void }) {
  const [itemType, setItemType] = React.useState<string>("");
  const [classification, setClassification] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    itemTypeId: "",
    classificationId: "",
    subClassificationId: "",
    name: "",
    description: "",
    check: false,
    isPublic: false,
    type: "CATEGORY",
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          itemTypeId: action[1]?.itemType?.id,
          classificationId: action[1]?.classification?.name,
          subClassificationId: action[1]?.subClassification?.name,
          check: true,
        }
      : {}),
  });

  const onSubmit = async (value: IFormData) => {
    const dataCreate = {
      itemTypeId: value.itemTypeId,
      parentId: value.subClassificationId,
      name: value.name,
      description: value.description,
      isPublic: value.isPublic,
      type: value.type,
    };

    const dataUpdate = {
      name: value.name,
      description: value.description,
      isPublic: value.isPublic,
    };

    setLoading(true);
    try {
      if (action && action[0] === "update") {
        await categoryApi.update(action[1].id, { ...dataUpdate });
      } else {
        await categoryApi.create(dataCreate);
      }

      Message.success("Таны хүсэлт амжилттай!");
      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const { data: itemData } = useSWR(
    `/erp/back_office/item_reference/item_type/select/category`,
    async () => {
      let res = await itemTypeApi.select({ query: "", limit: "" });
      return res;
    },
    {},
  );

  const { data: classificationData } = useSWR(
    `/erp/back_office/item_reference/category/select/category/${itemType}`,
    async () => {
      if (!itemType) {
        return [];
      }
      let res = await categoryApi.select({
        query: "",
        itemTypeId: itemType,
      });
      return res;
    },
    {},
  );

  const { data: subClassificationData } = useSWR(
    `/erp/back_office/item_reference/category/select/category/${itemType}/${classification}`,
    async () => {
      if (!classification && !classification) {
        return [];
      }
      let res = await categoryApi.select({
        query: "",
        itemTypeId: itemType,
        parentId: classification,
      });
      return res;
    },
    {},
  );

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout
            title="Категори"
            subTitle={
              action && action[0] !== "update"
                ? "Бараа бүтээгдэхүүн, ажил үйлчилгээний категори нэмэх"
                : "Бараа бүтээгдэхүүн, ажил үйлчилгээний категори засварлах"
            }
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />

            <Flex gap={15} direction="column">
              <Divider />
              <SelectField
                name="itemTypeId"
                label="Нэр төрөл"
                placeholder="сонгох"
                options={(itemData || []).map((c: IItemType) => ({ label: c.name, value: c.id }))}
                onChange={(e) => {
                  setFieldValue("classificationId", undefined);
                  setFieldValue("subClassificationId", undefined);
                  setItemType(e as string);
                }}
                disabled={action && action[0] === "update"}
                required
              />
              {action && action[0] === "update" ? (
                <TextField name="classificationId" label="Ангилал" placeholder="нэр" required disabled />
              ) : (
                <SelectField
                  name="classificationId"
                  label="Ангилал"
                  placeholder="сонгох"
                  options={(classificationData || []).map((c: ICategory) => ({ label: c.name, value: c.id }))}
                  disabled={!values.itemTypeId}
                  onChange={(e) => {
                    setFieldValue("subClassificationId", undefined);
                    setClassification(e as string);
                  }}
                  required
                />
              )}
              {action && action[0] === "update" ? (
                <TextField name="subClassificationId" label="Дэд ангилал" placeholder="нэр" required disabled />
              ) : (
                <SelectField
                  name="subClassificationId"
                  label="Дэд ангилал"
                  placeholder="сонгох"
                  options={(subClassificationData || []).map((c: ICategory) => ({ label: c.name, value: c.id }))}
                  disabled={!values.classificationId}
                  required
                />
              )}
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              <CheckboxField name="isPublic" label="Нээлттэй" />
              <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
              <Divider mb={10} />
            </Flex>

            <Flex justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
